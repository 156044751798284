import styled from 'styled-components'
import { Link as LinkGatsby_ } from 'gatsby'

export const ContBtnTarjeta = styled.div`
  position: relative;
  width: 98%;
  min-height: 50px;
  float: left;
  border: 1px solid #82CCDD;
  margin: 0px 5px 5px 5px;
  text-align: center;
  
  @media(min-width: 300px) and (max-width: 768px){
    width: 47%;
    height: 165px;
    margin: 0px 4px 5px 3px;
  }
  @media(min-width: 769px) and (max-width: 1023px){
    width: 32%;
    height: 165px;
    margin: 0px 4px 5px 4px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    width: 24%;
    height: 115px;
    margin: 0px 3px 5px 3px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    width: 24%;
    height: 120px;
  }
  @media(min-width: 1408px){
    width: 24%;
    height: auto;
  }
`

export const LinkGatsby = styled(LinkGatsby_)`
  width: 100%;
  height: 100%;
  padding: 20px 5px;
  color: #FFFFFF;
  text-decoration: none;
  display: block;

  &:hover{
    color: #82CCDD;
  }
  
  &[aria-current]{
    color: #82CCDD;
  }
`

export const ContName = styled.div`
  font-family: NotoSansRegular;
  
  @media(min-width: 300px) and (max-width: 768px){
    margin-bottom: 5px;
    font-size: 25px;
  }
  @media(min-width: 769px) and (max-width: 1023px){
    margin-bottom: 5px;
    font-size: 28px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    margin-bottom: 5px;
    font-size: 23px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    margin-bottom: 5px;
    font-size: 26px;
  }
  @media(min-width: 1408px){
    margin-bottom: 10px;
    font-size: 30px;
  }
`
export const ContDegree = styled.div`
  font-family: NotoSansRegular;
    
  @media(min-width: 300px) and (max-width: 768px){
    font-size: 14px;
  }
  @media(min-width: 769px) and (max-width: 1023px){
    font-size: 16px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    font-size: 15px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    font-size: 15px;
  }
  @media(min-width: 1408px){
    font-size: 15px;
  }
`

import React from 'react'
import { graphql } from "gatsby"
import Layout  from '../components/Layout'
import SEO from "../components/seo"
import {ContBtnTarjeta, ContDegree, ContName, LinkGatsby} from "../components/BtnTarjeta/styles";

export const query = graphql`
  query {
    allTarjetasJson {
      nodes {
        id
        code
        name
        degree
        description
        email
        phone
      }
    }
  }
`

const Tarjeta = ( { data } ) => {
  const tarjetas = data.allTarjetasJson

  return (
    <Layout
      title='Finanka Tarjeta '
      subtitle='Tu pasión, tu proyecto de vida…'
      works=''
    >
      <SEO title="Finanka Tarjeta " />

      <div>Sección de botones </div>

      {tarjetas.nodes.map(tarjeta => (
        <ContBtnTarjeta key={tarjeta.id}>
          <LinkGatsby to={`/${tarjeta.code}`}>
            <ContName>{tarjeta.name}</ContName>
            <ContDegree>{tarjeta.degree}</ContDegree>
          </LinkGatsby>
        </ContBtnTarjeta>
      ))}

    </Layout>
  )
}

export default Tarjeta
